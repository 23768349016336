import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutAccueil from "../../composants/layout/en/LayoutAccueil"
import SEO from "../../composants/layout/SEO"

import { obtenirPage } from "../../js/client-es/utils"

import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(filter: { code: { eq: "indexEN" } }) {
      ...FragmentPageYaml
    }
  }
`

export default function Home() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  return (
    <LayoutAccueil>
      <SEO page={page} />
    </LayoutAccueil>
  )
}
